import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/non-bank-financial-institutions/*" />
    <RAC path="/zh-cn/non-bank-financial-institutions/*" />
    <RAC path="/zh-tw/non-bank-financial-institutions/*" />
    <RAC path="/pl/non-bank-financial-institutions/*" />
    <RAC path="/ru/non-bank-financial-institutions/*" />
    <RAC path="/it/non-bank-financial-institutions/*" />
    <RAC path="/de/non-bank-financial-institutions/*" />
    <RAC path="/es/non-bank-financial-institutions/*" />
    <RAC path="/fr/non-bank-financial-institutions/*" />
    <RAC path="/ar/non-bank-financial-institutions/*" />
    <RAC path="/id/non-bank-financial-institutions/*" />
    <RAC path="/pt/non-bank-financial-institutions/*" />
    <RAC path="/ko/non-bank-financial-institutions/*" />
    <DynamicNotFoundPage default />
  </Router>
)
